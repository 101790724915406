<template>
  <div class="container" id="Login">
    <VideoCapture
      recordBtnContent="⬤"
      stopBtnContent="◼"
      cancelBtnContent="ⅹ"
      doneBtnContent="✓"
      uploadUrl="<example-server-address.com>"
      v-model="videoUrl"
    />
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
    <MenuRodape
      @OpenMenuLateral="menuOpen = true"
      :decoded="decoded"
    ></MenuRodape>
  </div>
</template>
<!-- eslint-disable -->
<script>
import locale from "../locale";
import service from "../services";
import jwt from "jsonwebtoken";
import Password from "vue-password-strength-meter";
import { PhotoCapture, VideoCapture } from "vue-media-recorder";
import MenuRodape from "./MenuRodape";
import MenuLateral from "./MenuLateral";
export default {
  name: "DashBoard",
  props: {
    msg: String,
  },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      imageBase64: "",
      videoUrl: "",
    };
  },
  components: { PhotoCapture, VideoCapture, MenuRodape, MenuLateral },
  methods: {},
  mounted() {
    //this.iniciaCamera();
    this.$validator.localize("en", locale);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.carregando {
  color: #fff;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  padding-top: 50%;
}

.logoRodape {
  text-align: center;
  margin-top: 15px;
  font-size: 10px;
  display: block;
}
video {
  width: 100%;
}
</style>
